@import "styles/common.scss";

.theme-dark{
  --color-primary: #1a1b1a;
  --color-secondary: #fff;
  --color-nav-link:#E5E5CB;
  --color-nav-link-hover:#ffffff;
  --font-size-primary-heading: 32px;
  --color-heading:#ffffff;
  --color-heading-hover:#ffffff;
  --color-sponsor-tier: #8a8a8a;
  --text-transform-primary-heading:uppercase;
  --font-weight-primary-heading: normal;
  --font-weight-secondary-heading: 500;
  --font-size-secondary-heading: 20px;
  --font-size-tertiary-heading: 14px;
  --font-weight-tertiary-heading: normal;
  --font-size-paragraph:16px;
  --line-height-secondary-heading: 27px;
  --line-height-paragraph:1.5;
  --border:1px solid #8a8a8a;
  --border-radius-primary: 2px;
  --bs-list-group-border-radius: 2px;
  --footer-height:50px;
  --box-shadow-modal: 0px 0px 28px 1px rgb(255 255 255 / 25%);
  --box-shadow-login: rgb(255 255 255 / 10%) 0px 0px 15px 2px;
  --box-shadow-floorplan: 0px 1px 4px var(--color-secondary);

  --color-label: var(--color-secondary);
  --background-color-disabled: #464646;
  --color-disabled: #959595;
  --background-color-primary-button: var(--color-primary);
  --background-color-primary-button-hover: var(--color-primary);
  --color-primary-button: #fff;
  --btn-primary-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --btn-primary-hover-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --sidebarItemBackground: #262626;

  --fontFamilyPrimary: 'Roboto', sans-serif;
  --fontFamilySecondary: 'Roboto', sans-serif;
  --defaultFontWeight: 300;

  --color-div-hover: #2b2b2b;
  //card styling 
  --card-border: 2px solid var(--color-div-hover);
  --card-border-radius: 8px;

}

.theme-auroraGlow {
  --color-primary: #154759; /* Background color */
  --color-secondary: #ffffff; /* Text color */
  --color-heading: #ff4081; /* Headings, links, and buttons */
  --color-heading-hover: #e5006e; /* Heading hover color */
  --color-primary-accent: #4caf50; /* Accent color 1 */
  --color-secondary-accent: #f9c532; /* Accent color 2 */
  --color-cta-button: #f9534e; /* CTA button color */
  --color-sponsor-tier: #8a8a8a;

  /* Font sizes, font weights, and other styles */
  --font-size-primary-heading: 32px; /* Primary heading font size */
  --font-weight-primary-heading: noraml; /* Primary heading font weight */
  --font-weight-secondary-heading: 500; /* Secondary heading font size */
  --font-size-secondary-heading: 20px; /* Secondary heading font weight */
  --line-height-secondary-heading: 27px;
  --font-size-tertiary-heading: 14px;
  --font-weight-tertiary-heading: normal;
  --font-size-paragraph: 16px; /* Paragraph font size */
  --line-height-paragraph: 1.5; /* Paragraph font size */
  --border: 1px solid var(--color-secondary); /* border used at most places */
  --border-radius-primary: 2px;
  --bs-list-group-border-radius: 2px;
  --footer-height: 50px; /* Footer height */
  --box-shadow-modal: 0px 0px 28px 1px rgba(0, 0, 0, 0.25); /* Modal box shadow */
  --box-shadow-login: rgba(0, 0, 0, 0.1) 0px 0px 15px 2px; /* login wrapper div box shadow */
  --box-shadow-floorplan: 0px 1px 4px var(--color-secondary); /* Floorplan utility button's shadow */
  --color-nav-link: #f9c532; /* Navigation link/ nav-item color */
  --color-nav-link-hover: #ffffff; /* Navigation link hover color */
  --color-label: var(--color-secondary); /* Input label color */
  --background-color-disabled: #7a7a7a; /* disabled input color */
  --color-disabled: #d9f5ff; /* disabled text / disabled link color */
  --background-color-primary-button: var(--color-heading);
  --background-color-primary-button-hover: var(--color-heading);
  --color-primary-button: #ffffff; /* Button text color */
  --btn-primary-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --btn-primary-hover-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  // --sidebarItemBackground: #f6f6f6;
  --sidebarItemBackground: #0e526b;
  --fontFamilyPrimary: 'Roboto', sans-serif;
  --fontFamilySecondary: 'Roboto', sans-serif;

  --color-div-hover: #0e526b;
  //card styling 
  --card-border: 2px solid var(--color-div-hover);
  --card-border-radius: 8px;
}
.theme-seascape {
  --color-primary: #ffffff; /* Background color */
  --color-secondary: #000000; /* Text color */
  --color-heading: #000000; /* Headings, links, and buttons */
  --color-heading-hover: #000000; /* Heading hover color */
  --color-sponsor-tier: #8a8a8a;

  /* Font sizes, font weights, and other styles */
  --font-size-primary-heading: 32px; /* Primary heading font size */
  --font-weight-primary-heading: normal; /* Primary heading font weight */
  --font-weight-secondary-heading: 500; /* Secondary heading font size */
  --font-size-secondary-heading: 20px; /* Secondary heading font weight */
  --line-height-secondary-heading: 27px;
  --font-size-tertiary-heading: 14px;
  --font-weight-tertiary-heading: normal;
  --font-size-paragraph: 16px; /* Paragraph font size */
  --line-height-paragraph: 1.5; /* Paragraph line height */
  --border: 1px solid #d6d6d6; /* border used at most places */
  --border-radius-primary: 2px;
  --bs-list-group-border-radius: 2px;
  --footer-height: 50px; /* Footer height */
  --box-shadow-modal: 0px 0px 28px 1px rgba(0, 0, 0, 0.25); /* Modal box shadow */
  --box-shadow-login: rgba(0, 0, 0, 0.1) 0px 0px 15px 2px; /* login wrapper div box shadow */
  --box-shadow-floorplan: 0px 1px 4px var(--color-secondary); /* Floorplan utility button's shadow */

  --color-nav-link: var(--color-heading); /* Navigation link/ nav-item color */
  --color-nav-link-hover: var(--color-heading-hover); /* Navigation link hover color */

  --color-label: rgba(0, 0, 0, 0.8); /* Input label color */
  // --background-color-disabled: rgba(0, 0, 0, 1); /* disabled input color */
  --background-color-disabled: #f4f4f4;
  --color-disabled: #707070; /* disabled text / disabled link color */
  --background-color-primary-button: #385371;
  --background-color-primary-button-hover: #385371;
  --color-primary-button: #ffffff; /* Button text color */
  --btn-primary-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --btn-primary-hover-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --sidebarItemBackground: #f5f5f5;
  --fontFamilyPrimary: 'Roboto', sans-serif;
  --fontFamilySecondary: 'Roboto', sans-serif;

  --color-div-hover: #f5f5f5;
  //card styling 
  --card-border: 2px solid var(--color-div-hover);
  --card-border-radius: 8px;
}

.theme-delphinium{
  --color-primary: #ffffff; /* Background color of most of the website */
  --color-secondary: #004e72; /* Text color such as paragraphs and dark elements */
  --color-heading: #004e72; /* Heading colors, link colors, and button background colors */
  --color-heading-hover: #004261; /* Heading hover color */
  --color-primary-accent: #f9534e; /* Accent color 1 (used in very few places) */
  --color-secondary-accent: #a9bcc9; /* Accent color 2 (used in very few places) */
  --color-cta-button: #f9534e; /* CTA button color */
  --color-sponsor-tier: #8a8a8a;

  /* Font sizes, font weights, and other styles */
  --font-size-primary-heading: 32px; /* Primary heading font size */
  --font-weight-primary-heading: normal; /* Primary heading font weight */
  --font-weight-secondary-heading: 500; /* Secondary heading font weight */
  --font-size-secondary-heading: 20px; /* Secondary heading font size */
  --line-height-secondary-heading: 27px;
  --font-size-tertiary-heading: 14px;
  --font-weight-tertiary-heading: normal;
  --font-size-paragraph: 16px; /* Paragraph font size */
  --line-height-paragraph: 1.5; /* Paragraph line height */
  --border: 1px solid var(--color-secondary); /* Border used in most places */
  --border-radius-primary: 2px;
  --bs-list-group-border-radius: 2px;
  --footer-height: 50px; /* Footer height */
  --box-shadow-modal: 0px 0px 28px 1px rgba(0, 0, 0, 0.25); /* Modal box shadow */
  --box-shadow-login: rgba(0, 0, 0, 0.1) 0px 0px 15px 2px; /* Login wrapper div box shadow */
  --box-shadow-floorplan: 0px 1px 4px var(--color-secondary); /* Floorplan utility button's shadow */
  --color-nav-link: #004e72; /* Navigation link/nav-item color */
  --color-nav-link-hover: #004261; /* Navigation link hover color */
  --color-label: var(--color-secondary); /* Input label color */
  --background-color-disabled: #7a7a7a; /* Disabled form input color */
  --color-disabled: #d9f5ff; /* Disabled/muted text/disabled link color */
  --background-color-primary-button: var(--color-heading); /* Button background color for most buttons */
  --background-color-primary-button-hover: var(--color-heading-hover); /* Primary button hover background color */
  --color-primary-button: #ffffff; /* Button text color */
  --btn-primary-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --btn-primary-hover-border: 1px solid rgba(128, 128, 128, 0.3490196078);
  --sidebarItemBackground: #0e526b; /* Used for highlighting small sections (background color) such as card backgrounds like session details card, even hover color for table rows */
  --fontFamilyPrimary: 'Roboto', sans-serif;
  --fontFamilySecondary: 'Roboto', sans-serif;
} 

.theme-auroraGlow *, .theme-curiousBlue *, .theme-dark *, .theme-seascape *, .theme-delphinium{
  background-color: inherit;
}

.theme-auroraGlow .alert.alert-danger,
.theme-dark .alert.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.theme-auroraGlow .alert.alert-success,
.theme-dark .alert.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

svg{
  background-color: transparent !important;
}

img{
  font-size: 10px;
}

/* Style the option elements */
select option {
  background-color: var(--color-primary) !important;
  color: var(--color-secondary) !important;

  :first-child{
    border-top-left-radius: var(--border-radius-primary);
    border-top-right-radius: var(--border-radius-primary);
  }

  :last-child{
    border-bottom-left-radius: var(--border-radius-primary);
    border-bottom-right-radius: var(--border-radius-primary);
  }
}

/* Style the hover state of option elements */
select option:hover {
  background-color: var(--sidebarItemBackground) !important;
}

.tooltip, .tooltip-arrow{
  background-color: transparent;
}

.tooltip-arrow::before{
  border-top-color: var(--color-primary) !important;
}

.tooltip-inner{
  background-color: var(--color-primary);
  color: var(--color-secondary);
}

.section {
  margin-bottom: 40px;
}

.text-gray {
  color: rgba(255, 255, 255, 0.55);

  &:hover {
    color: rgba(255, 255, 255, 0.75);
  }
}

.text-litegray {
  color: var(--color-nav-link);
  font-weight: 500;

  span{
    color: inherit;
  }

  // &:hover {
  //   color: var(--color-nav-link-hover);
  // }
}

.text-primary{
  color: var(--color-heading) !important;

  &:hover{
    color: var(--color-heading-hover) !important;
  }
}

.pointer{
  cursor: pointer;
}

.skiptranslate{
  
  iframe{
    visibility: hidden !important;
  }
}

#googleTranslateElement {
  width: fit-content;
  display: inline-block;
  vertical-align: bottom;

  .goog-te-gadget-simple{
    // border: var(--border);
    // border-radius: var(--border-radius-primary);
    // padding: 4px 2px;
    border: 0;
    padding: 0.5rem 1px;

    img{
      display: none;
    }

    span{
      font-size: 1rem;
      color: var(--color-nav-link);
      font-weight: 500;
      border: none !important;
      font-family: var(--fontFamilyPrimary);

      span:first-child{
        font-size: 0px;
        letter-spacing: -1px;
        &::before{
          display: inline-block;
          content: 'Language';
          font-size: 1rem;
          color: var(--color-nav-link);
          font-weight: 500;
          border: none !important;
          font-family: var(--fontFamilyPrimary);
          letter-spacing: normal;
        }
        &::after{
          display: inline-block;
          margin-left: 0.255em;
          vertical-align: 0.255em;
          content: "";
          border-top: 0.3em solid;
          border-right: 0.3em solid transparent;
          border-bottom: 0;
          border-left: 0.3em solid transparent;
          font-size: 1rem;
          color: var(--color-nav-link);
          font-weight: 500;
          font-family: var(--fontFamilyPrimary);
        }
      }
    }

    span[aria-hidden="true"]{
      display: none;
    }
  }

  .goog-te-gadget-icon{
    display: none;
  }
}

span.indicator{
  display: none;
}


// hide the pop-up when hovered over a work/text
#goog-gt-tt{
  visibility: hidden !important;
  display: none !important;
}

.disabled, .disabled span {
  pointer-events: none;
  color: var(--color-disabled) !important;
  font-weight: 500 !important;
  cursor: not-allowed;
}

.underlined{
  text-decoration: underline;
}

.no-border {
  border: 0 !important;
  border-radius: 0 !important;
}

.no-padding {
  padding: 0 0 0 0 !important;
}

.pos-relative {
  position: relative;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 20px;
}

.flex {
  display: flex;
}

.alignCenter{
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled{
  pointer-events: none;
  color: var(--color-disabled);
  background-color: var(--background-color-disabled);
  font-weight: 500;
  cursor: not-allowed;
  opacity: 0.9;
}

.switchSpinnerContainer{
  width: 45px;

  .spinner-component{
    color: var(--background-color-primary-button);
  }
}

.theme-dark .spinner-component{
  color: var(--color-primary-button);
}

.tableWrapper, .table{
  padding: 16px;
  background-color: inherit;
  border-radius: var(--border-radius-primary);
  // text-align: center;

  th{
    background-color: var(--color-primary);
  }

  *:not(button, a, thead), *:not(.button-primary) > *:not(.button-primary), 
  .table>:not(caption)>*:not(.button-primary)>*:not(.button-primary){
    background-color: transparent;
  }
}

.tableWrapper, .table{
  padding: 16px;
  background-color: inherit;
  border-radius: var(--border-radius-primary);
  // text-align: center;

  th{
    background-color: var(--color-primary);
  }

  *:not(button, a, thead), *:not(.button-primary) > *:not(.button-primary), 
  .table>:not(caption)>*:not(.button-primary)>*:not(.button-primary){
    background-color: transparent;
  }
}

.tableWrapper tbody tr:hover{
  background-color: var(--sidebarItemBackground);
}

font{
  background-color: inherit;
  color: inherit;
}

.formBtnContainer{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;

  &.reversed{
    flex-direction: row-reverse;
  }
}

.nav-tabs .nav-link, .card > .list-group{
  border-radius: var(--border-radius-primary);
}

.accordion-item{
  border-radius: var(--border-radius-primary);
}

.accordion-item:first-of-type{
  border-top-left-radius: var(--border-radius-primary);
  border-top-right-radius: var(--border-radius-primary);

  .accordion-button{
    border-radius: inherit;
  }
}
.accordion-item:last-of-type{
  border-bottom-right-radius: var(--border-radius-primary);
  border-bottom-left-radius: var(--border-radius-primary);
  
  .accordion-button{
    border-radius: inherit;
  }
}

.accordion-button:not(.collapsed){
  background-color: var(--sidebarItemBackground);
  color: var(--color-secondary);
}

.theme-dark {
  .navbar-toggler-icon{
    background-color: transparent;
    filter: invert(1);
  }
  .accordion-button::after{
    filter: invert(0.5);
  }

  .react-tel-input .flag-dropdown.open .country-list{
    &::-webkit-scrollbar{
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fff;
    }
    
    &::-webkit-scrollbar-track {
      background-color: #2e2e2e;
    }
  }
}

.accordion-button{
  color: inherit;

  button{
    background-color: var(--background-color-primary-button);
    color: var(--color-primary-button);
  }
}


.form-select{
  height: 50px;
  width: auto;
  border-radius: var(--border-radius-primary);
  background-color: var(--color-primary);
  color: var(--color-secondary);
  border: var(--border);

  &:focus{
    box-shadow: none;
    border-color: initial;
  }
}

// used in different pages
.sidebarItemWrap{
  padding: 16px;
  background-color: var(--sidebarItemBackground) !important;
}

.button-primary{
  background-color: var(--background-color-primary-button);
  color: var(--color-primary-button);
  border: var(--btn-primary-border);
  border-radius: var(--border-radius-primary);
  transition-duration: .2s;
  min-width: fit-content;
  padding: 7.34px 12px;

  &.secondary{
    background-color: var(--color-primary-button);
    color: var(--background-color-primary-button);
    
    svg{
      fill: var(--background-color-primary-button);
    }

    > *{
      color: var(--background-color-primary-button);
    }

    &:hover{
      background-color: var(--background-color-primary-button);
      color: var(--color-primary-button);

      > *{
        color: var(--color-primary-button);
      }

      svg{
        fill: var(--color-primary-button);
      }
    }
  }
  
  span, div{
    background-color: transparent;
    color: var(--color-primary-button);
  }

  svg{
    fill: var(--color-primary-button);
  }

  &:hover {
    background-color: var(--background-color-primary-button-hover);
    color: var(--color-primary-button);
    border-color: var(--color-secondary);
  }

  &:active, &.active, &:focus-visible{
    background-color: var(--background-color-primary-button-hover) !important;
    color: var(--color-primary-button) !important;
    border-color: var(--color-secondary) !important;
  }

  &:disabled{
    background-color: var(--background-color-disabled);
    color: var(--color-disabled);
    border: var(--btn-primary-border);
  }
}

button:focus:not(:focus-visible) {
  box-shadow: none;
}

.heading-primary {
  color: var(--color-heading);
  font-size: var(--font-size-primary-heading);
  font-weight: var(--font-weight-primary-heading);
  font-family: var(--fontFamilyPrimary);
  margin-bottom: 12px;
}

.heading-secondary {
  color: var(--color-heading);
  font-size: var(--font-size-secondary-heading);
  font-weight: var(--font-weight-secondary-heading);
  line-height: var(--line-height-secondary-heading);
  font-family: var(--fontFamilyPrimary);
  margin-bottom: 8px;
}

.heading-tertiary {
  color: var(--color-sponsor-tier);
  font-size: var(--font-size-tertiary-heading);
  font-weight: var(--font-weight-tertiary-heading);
  font-family: var(--fontFamilyPrimary);
  margin-bottom: 5px;
}


.text-link {
  font-weight: bold;
  color: var(--color-heading);

  &:hover {
    color: var(--color-heading-hover);
  }
}

a {
  color: var(--color-nav-link);
  transition-duration: 0.1s;

  &.btn.button-primary {
    color: var(--color-primary-button);
    border: var(--btn-primary-border);

    &:hover {
      text-decoration: none;
      background-color: var(--background-color-primary-button-hover);
      color: var(--color-primary-button);
      border-color: var(--color-secondary);
    }

    &.secondary{
      background-color: var(--color-primary-button);
      color: var(--background-color-primary-button);
  
      &:hover{
        background-color: var(--background-color-primary-button);
        color: var(--color-primary-button);
      }
    }
  }

  &:hover {
    color: var(--color-nav-link-hover);
    transition-duration: 0.1s;
    text-decoration: none;
  }
}

.right-aligned-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px;
  margin-left: 12px;

  td{
    border: 0;
  }
}

.right-aligned-container-body {
  /* padding: 1rem; */
  margin: 0;

  p {
    margin: 10px;
    background: transparent;
  }
}

.main-wrapper {
  // container-type: inline-size;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}

.wrapperContainer{
  padding: 12px 20px;
}

.exhibitorHomeWrapper a, .highlightedLink{
  color: var(--color-heading) !important;

  &:hover {
    text-decoration: underline;
  }
}

.exhibitorHomeWrapper span {
  color: inherit !important;
  background-color: transparent !important;
}

.body-content-wrapper {
  // min-height: 85vh;
  // height: 85%;
  min-height: 70vh;
  height: 70%;
  padding: 0 12px;
}

/* Login component */

.login, .auth-left {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: calc(90vh - var(--footer-height));
}

.login-wrapper {
  // width: 400px;
  // // height: 415px;
  // text-align: center;
  // border-radius: 25px;
  // box-shadow: var(--box-shadow-login);
  // justify-content: center;

  &.provider {
    height: 300px;
    width: 475px;
  }
}

.social-login { 
  // margin-top: 0.5rem;    
  margin-bottom: 12px;
  box-shadow: 2px 4px 5px 0px var(--sidebarItemBackground);
  border-radius: 50px;

  button {
    width: 100%;
    display: flex;
    border: 0;
    outline: 0;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    align-items: baseline;
    -webkit-box-align: baseline;
    max-width: 100%;
    cursor: pointer;
    padding: 0 1vw;
    /* padding: 0 5vw; */
    /* line-height: 3rem; */
    margin-top: 24px;
    justify-content: center;
    // width: 100%;
    /* border-radius: 25px; */
    white-space: pre;
  }
}



.social-login-logo, .social-login-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.social-login-logo {
  align-self: center;
  margin: 0 2px;
}

.social-login-text {
  flex: 1;
  // font-weight: 650;
  // border-radius: 25px;
  width: 100%;
  margin-top: 12px;
  font-weight: var(--font-weight-secondary-heading);
}

.social-login-logo {
  svg, img {
    height: 22px;
    width: 22px;
  }
}

.login.Exhibitor{
  .login-wrapper{
    width: auto;
    max-width: 100%;
    box-shadow: none;
  }

  .exhibitorLogin-helpText{
    margin-top: 24px;
  } 

  .social-login-wrapper,
  .social-login-subWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 48px;
  }
  // .social-login-subWrapper{
  //   flex-direction: column;
  //   margin-top: 24px;
  //   padding: 0 50px;
  // }
  .social-login{
    position: relative;

    // &:first-child{
    //   margin-right: 48px;
    // }
  }

  // .social-login-subWrapper:first-child .social-login::after{
  //   content: "";
  //   position: absolute;
  //   top: 50%;
  //   right: -50px; 
  //   width: 1px;
  //   height: 200%;
  //   background-color: var(--color-secondary);
  //   transform: translate(0, -50%);
  // }
}

.loginFormWrapper{
  width: 100%;
  min-width: fit-content;
  max-width: 350px;
}

.other-login-wrapper {
  margin-top: 12px;
  text-align: center;

  .social-login {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    button {
      position: relative;
      border: 1px solid #eee;
      width: 40px;
      height: 40px;
      box-shadow: 2px 4px 5px 0px var(--sidebarItemBackground);
      border-radius: 50px;
      padding: 0;
      margin: 0 8px;
    }
  }
}


/* Sidebar */

.modalFooter.buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
}

/* MyShow Component */

.myshow-noresult {
  min-height: 85%;
}

/* Other Component */

.btn-component {
  width: 100px;
}

.spinner-component {
  width: 18px;
  height: 18px;
  border-width: 3px;
}

/* Loader Screen */

.fullScreenLoaderContainer{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  background-color: rgba(255, 255, 255, 0.507) !important;
  filter: blur(0px);
  top: 0;
  left: 0;
}

.full-screen-loader-container {
  --loader-background: rgba(255, 255, 255, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  
  .loader-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--loader-background);
    backdrop-filter: blur(1px);
    z-index: 0;
  }

  .loader-content {
    z-index: 1;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.loader-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary);
}

.loader-spinner {
  /* background-color: red; */
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    position: absolute;
    border: 4px solid var(--color-primary);
    opacity: 1;
    border-radius: 50%;
    animation: loader-spinner 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }
}

@keyframes loader-spinner {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }

  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* Exhibitor Dashboard Page */

.dashboard-wrapper {
  /* height: calc(90vh - var(--footer-height)); */
  width: 100%;
  margin: 0 auto;
  height: 100%;

  .ui.search-form{
    width: 100%;
  }

}

.nav-tabs button, .myshowWrapper .nav-tabs button{
  height: 50px;
  padding: 7px 12px;
}

.productsWrapper nav, .attendeesWrapper nav, .invoiceContainer nav{
  margin-bottom: 24px;
}

.shows-list-wrapper nav{
  display: flex;
}

.search-bar-wrapper {
  padding-top: 2rem;
}

.orgFilterWrap {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboard-wrapper .search-bar {
  margin: 0;
}

#nav-tab-exhibitorHome, #myshowTabs{
  border-bottom: var(--border);

  .nav-link.active{
    border-bottom: 1px solid;
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }
}

#nav-tab-exhibitorHome, #myshowTabs{
  width: fit-content;

  .tabList{
    display: flex;
    margin-bottom: 1px;
  }
}

.show-card-list {
  justify-items: center
}

// .show-info-card * {
//   z-index: 1;
// }

// .show-info-card::before {
//   content: '';
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   height: 100%;
//   background: linear-gradient(135deg, rgba(8, 84, 132,1), rgba(8, 84, 132, 0.2));
//   filter: blur(25px);
//   z-index: 0;
//   transform: scale(0);
// }

// .show-info-card.card:hover::before {
//   transform: translate(2px, 30px) scale(0.85);
// }

#nav-active .show-card-body.currentShow{
  box-shadow: var(--box-shadow-login);
}

.userStatusForShow {
  font-weight: 500;
}

.show-info-card {
  height: 100%;
  text-align: center;

  .card-title{
    background-color: transparent;
    margin-bottom: 0;
  }
}

.show-card-body {
  width: 100%;
}


.show-info-card .card-title a, .show-info-card .card-title a .showName {
  color: var(--color-heading);
  transition-duration: 0.1s;
  white-space: pre-wrap;
  font-size: var(--font-size-secondary-heading);
  margin-bottom: 0;

  &:hover {
    color: var(--color-heading-hover);
    transition-duration: 0.1s;
  }
}

.card-list {
  border: 0;
  display: grid;
  grid-auto-rows: minmax(auto, auto);
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  flex-wrap: wrap;
  padding: 24px 0;
  margin: 0 auto;
  transition-duration: 0.1s;
}

.card > .list-group {
  border-top: 0;
  height: -webkit-fill-available;
  display: flex;
  align-items: center;
  justify-content: center;

  /* background: transparent; */
  --bs-list-group-bg:none;
}

.show-card-details {
  padding: 15px 0;
}

.card .list-group .list-group-item {
  line-height: 1;
  color: var(--color-secondary);
  background-color: transparent;
}

.exhibitor-booth-number {
  display: inline;
  border-right: 1px solid #3d3de2;
  color: var(--color-nav-link);
  font-weight: normal;

  &:hover {
    color: var(--color-nav-link-hover);
    cursor: pointer;
  }

  &:last-child {
    border: 0;
  }
}

.card-list.exhibitor-list {
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
}

.card-list-wrapper {
  max-width: 100%;
  margin-top: 24px;

  .container{
    padding: 0;
    max-width: 100%;
  }
}

.card {
  width: 100%;
  margin: 0 auto;
  border: var(--border);
}

.card, .card-body{
  border-radius: var(--border-radius-primary);
}

.nav-item, 
#googleTranslateElement, 
#googleTranslateElement *, 
#googleTranslateElement .goog-te-gadget-simple *,
.dropdown-toggle, .dropdown-toggle *{
  background-color: inherit;
}

.card-body.showName {
  border-bottom: var(--border);

  .navbar-brand{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: var(--fontFamilyPrimary);
    font-weight: var(--font-weight-primary-heading);
  }

  .showLogo{
    img{
      height: 32px;
      width: auto;
      margin-right: 10px;
    }
  }
}
.card {
  transition-duration: 0.1s;

  &:hover {
    transform: translateY(-1px);
    transition-duration: 0.1s;
    background-color: var(--sidebarItemBackground);
  }

  *{
    background-color: inherit;
  }
}

svg.bookmark-svg {
  transition-duration: 0.1s;
}

.card:hover svg.bookmark-svg {
  transform: scale(1.1);
  transition-duration: 0.1s;
}

.bookmark-failed-message {
  animation: 4s ease-in-out 0s 1 slideUp;
  opacity: 0;
  position: absolute;
  font-size: 12px;
  font-weight: 500;
  width: max-content;
  top: -5px;
  right: -5px;
  padding: 5px;
  color: var(--color-heading);
  border-radius: var(--border-radius-primary);
  border: 1px solid var(--bs-border-color-translucent);
  box-shadow: 0px 2px 6px -2px var(--bs-border-color-translucent);
}

@keyframes slideUp {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 100%;
  }

  70% {
    opacity: 100%;
  }

  100% {
    opacity: 0;
  }
}

/* exhibitor portal -> exhibitors page / update exhibitors page */

.exhibitorsHeading {
  display: flex;

  .heading-primary {
    flex: 1;
  }
}

/* Sessions page */

.session-list-wrapper {
  min-height: calc(90vh - var(--footer-height));
}

.sessionTrack .track {
  white-space: nowrap;
  display: block;
}
.sessionTrack .track:not(:last-child)::after{
  content: ", ";
}

.container {
  transition-duration: 0.1s;
}

/* Modal */

.modal, .modalWrapper, .invoiceSelect{
  background-color: transparent;
}

.modalWrapper {
  animation: 0.1s ease-out 0s 1 blura;
  animation-fill-mode: forwards;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2rem 0 2rem 0;
  /* transition-duration: .5s; */
}
.modal.success,
.modal.failure {
  .modalWrapper {
    padding: 7rem 0 7rem 0;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .modal-icon {
    svg {
      path {
        color: var(--background-color-primary-button);
      }
    }
  }
  .modalHeader {
    margin: 0;
    margin-top: 1rem;
    justify-content: center;

    .heading-primary { 
      font-weight: 600;
      margin-bottom: 0;
    }
  } 
  .modalBody {
    margin: 1rem;
  }
  .modalFooter {
    margin: 0;
    margin-top: 0.5rem;

    .btn.button-primary {
      padding: 10px 25px;
      border-radius: 5px;
    }
  }
  .closeModalBtn {
    height: 30px;
    width: 30px;
  }
}

.modal.success .modalBody .modal-link {
  text-decoration: underline;
  margin-right: 5px;
  cursor: pointer;
}

@keyframes blura {
  0% {
    backdrop-filter: blur(1px) saturate(0.8);
  }

  100% {
    backdrop-filter: blur(2px) saturate(0.9);
  }
}

.modalContainer {
  animation: 0.1s ease-out 0s 1 popUpFromCenter;

  /* padding-top: 2rem; */
  animation-fill-mode: forwards;
  width: 500px;
  background-color: var(--color-primary);
  box-shadow: var(--box-shadow-modal);
  border-radius: 25px;
  position: relative;

  /* display: flex;
  flex-direction: column; */

  /* scrollable modal */
  max-height: 90vh;
  max-width: 90%;
  overflow-x: hidden;
  overflow-y: hidden;

  .modalwrapperContainer{
    max-height: 90vh;
    overflow-y: auto;
  }
}

.modalContent {
  width: calc(100% - 5px);
  height: calc(100% - 20px);
  margin: 10px 0;
  padding: 12px;
  overflow-x: hidden;
  overflow-y: auto;
}

.modalwrapperContainer, .modalContent {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background: var(--sidebarItemBackground);
  }

  &::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3); */
    border-radius: 10px;
  }
}

@keyframes popUpFromCenter {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.closeModalBtn {
  background-color: transparent;
  position: absolute;
  top: 25px;
  right: 25px;
  height: 15px;
  width: 15px;
  padding: 2px;
  transform: scale(1);

  &:active {
    transform: scale(0.9) rotate(90);
  }
}

.theme-dark .closeModalBtn{
  filter: invert(1);
}

.modalHeader {
  display: flex;
  flex-direction: row;

  /* align-items: center; */
  justify-content: space-between;
  margin: 2rem;
  color: var(--color-heading);
  font-size: var(--font-size-primary-heading);
  font-weight: var(--font-weight-primary-heading);
}

.modalBody {
  margin: 2rem;
  font-size: var(--font-size-paragraph);
  line-height: var(--line-height-paragraph);
}

.modalFooter {
  margin: 2rem;
}

/* Exhibitor Registration Form */

.exhibitorForm-formFields {
  display: flex;
  flex-direction: row-reverse;
  gap: 24px;

  > div {
    flex: 1;
  }
}

.exhibitorForm-exhibitingInterest{
  margin-top: 35px;
  .formField.checkboxField{
    display: flex;
    align-items: center;

    label{
      margin: 0;
      margin-right: 12px;
    }

    .switchCheckboxContainer {
      width: auto;
    }
  }
}

.exhibitorForm-showName {
  font-weight: 700;
  font-size: 18px;
}

.formField.exhibitorForm-submitBtn {
  margin-top: -1rem;
}

#exhibitorForm-errors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  grid-column-gap: 20px;
}

/* Show my planner css */

.myshowWrapper nav{
  margin: 24px 0 0;
}

.bookmarked-exhibitors-wrapper, .bookmarked-sessionslist-wrapper{
  margin-bottom: 20px;
}

.bookmarked-exhibitors-wrapper .myshow-noresult{
  padding: 24px 0;
}

.bookmarked-sessions-myshow-list{
  padding: 24px 0;
}

.rightAlignedWrapper{
  min-height: 40px;
}

.right-aligned{
  text-align: right;
}

.rightAligned{
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .bookmark{
    position: relative;
    right: auto;
    top: auto;
  }
}

.addToCalendar{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--sidebarItemBackground);
  transition-duration: .2s;

  &:hover{
    box-shadow: 1px 1px 9px -6px var(--color-secondary);
  }

  svg{
    width: calc(100% - 5px);
    height: calc(100% - 5px);
  }
}

.myshow-listcard.card:hover .addToCalendar{
  background-color: var(--color-primary);
}

.bookmarked-exhibitors-myshow, .bookmarked-sessions-myshow {
  color: var(--color-heading);
  font-size: var(--font-size-primary-heading);
  font-weight: var(--font-weight-primary-heading);
}

.myshow-listcard {
  margin: 24px 0;
  height: 100%;
  
  // .sessionName{
  //   flex: 1;
  // }

  &:first-child{
    margin-top: 0;
  }
}

ul.mb-3.nav.nav-tabs.nav-justified {
  margin-top: 1rem;
}

/* booth order invoice page */

a {
  text-decoration: none;
  cursor: pointer;
}

.invoiceDataNotFound {
  padding: 1rem 1rem 2rem;
  color: var(--color-heading);
  text-align: center;
  font-size: var(--font-size-primary-heading);
  font-weight: var(--font-weight-primary-heading);
}

.table-buttons{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 10px;
}

.creditCardIcon{
  width: 15px;
  height: 15px;
}

.heading-btn-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.btnWithIcon{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
}

// charge summary
.heading-invoice-number.disabled{
  background-color: inherit;
}

.invoice-summary{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 12px;
  margin: 0 0 12px;
  border-radius: var(--border-radius-primary);
  background-color: var(--sidebarItemBackground);

  *, * > *{
    background-color: inherit;
  }
}

.invoice-summary-item{
  // flex: 1;
  position: relative;
  text-align: center;
  padding: 12px;

  .invoice-summary-item-label{
    font-weight: 400;
    // font-size: 16px;
    color: var(--color-disabled);
  }

  .invoice-summary-item-value{
    font-weight: 500;
    font-size: var(--font-size-secondary-heading);
    color: var(--color-heading);
    margin-top: 12px;
  }
}
.invoice-summary-item:not(:last-child)::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 60%;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(147,147,147,1) 50%,rgba(0,0,0,0) 100%);
  display: block;
  right: -5px;
  top: 50%;
  transform: translate(0, -50%);
}

.charges-table-row {
  line-height: 25px;
  margin: 0;
  padding: 0;
}

.charges-table-label {
  padding: 0;
  margin: 0;
  text-align: right;
}

.charges-table-content {
  padding: 0;
  margin: 0;
  text-align: left;

  .charges-table-values {
    margin-left: 5px;
  }
}

.charges-grid-container{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 12px;

  .charge-grid-item{
    border-radius: var(--border-radius-primary);
    background-color: var(--sidebarItemBackground);
    padding: 12px;

    div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background-color: var(--sidebarItemBackground);
    } 

    .table{
      border-bottom: 0;
    }

    *, * > *{
      background-color: inherit;
    }
  }
}
 
.showCard-table {
  margin: 0;
  border-bottom: var(--border);

  td{
    padding: 6px; 
  }   
  .list-group .list-group-item{
    padding: 12px;
  }
}


.charges-table {
  margin: 0;
  border-bottom: var(--border);

  td{
    padding: 6px;
    flex: 1; 
  } 
  tr {
    display: flex;
    align-items: center;
    justify-content: start;
  }  
}



.showCard-table-body {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: 0 35px;

  .location{
    display: flex;
  }
}

.charges-table-body {
  border: none;
  display: flex;
  flex-direction: column;
}

.showCard-table-row,
.charges-table-row {
  line-height: 20px;
  margin: 0;
  padding: 0; 
  /* border-bottom: none; */
}

.showCard-table-label,
.charges-table-label {
  // text-align: right;
  border-bottom: none;
  padding-right: 0 !important;
}

.showCard-table-value,
.charges-table-value {
  /* margin-left: 5px; */
  text-align: left;
  border-bottom: none;
}

.edit-text, .cancel-text {
  font-weight: bold;
}

.btn-component.cancel-btn, .cancel-button {
  margin-left: 5px;
}

.table-buttons {
  font-size: 16px;
  color: inherit;
}
.no-outline{
  background-color: red !important;
}
/* .right-aligned-container{ display: invoice ? "" : "none" } */

#react-select-2-listbox{
  z-index: 2;
}

.excursion-day .formField .label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;

  .label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
  }

  // .label-wrapper span.label {
  //   width: 450px;
  // }
  // .label-wrapper div.label {
  //   width: 225px;
  // }
}

.formField{
  &.multiSelect > div:first-child:focus-visible{
    outline: 0;
    border: 0;
  }
  .react-select__control{
    border: var(--btn-primary-border);
    border-radius: var(--border-radius-primary);
    background-color: inherit;

    &:hover{
      // border: var(--btn-primary-hover-border);
      border: var(--btn-primary-border);
      border-radius: var(--border-radius-primary);
    }
  }

  .react-select__menu, .react-select__menu > *{
    background-color: var(--color-primary) !important;
    color: inherit;
  }
  .react-select__menu{
    margin-top: 0;
    z-index: 2;
  }

  .react-select__control--is-focused.css-t3ipsp-control{
    border: var(--btn-primary-hover-border);
    outline: none !important;
  }

  .react-select__input:focus, .react-select__input:valid:focus-visible, .react-select__input:valid:focus{
    outline: none !important;
    border: none !important;
  }
  .react-select__value-container{
    padding: 7px 12px;
    color: inherit;
    background-color: inherit;
  }

  .react-select__single-value{
    z-index: 1;
    color: inherit;
    background-color: inherit;
    font-weight: initial;
  }

  .react-select__indicators, .react-select__dropdown-indicator{
    background-color: inherit;
  }

  .react-select__input{
    color: var(--color-secondary) !important;
  }

  .react-select__input-container{
    padding: 0;
    margin: 0;
  }

  .react-select__multi-value{
    margin: 0;
    padding: 0;
    margin-right: 4px;
  }

  .react-select__multi-value__label{
    margin: 0;
    padding: 0;
  }
  
  .react-select__option{
    &:hover, &:active{
      background-color: var(--sidebarItemBackground) !important;
    }
  }

  .react-select__option--is-disabled{
    background-color: var(--background-color-disabled) !important;
    color: var(--color-disabled);

    &:hover, &:active{
      background-color: var(--background-color-disabled) !important;
      color: var(--color-disabled) !important;
    }
  }

  .react-select__option--is-focused {
    background-color: transparent;
  }

  .react-select__option--is-selected{
    background-color: var(--sidebarItemBackground) !important;
    color: inherit;
  }

  .react-select__indicator-separator{
    margin: 0;
    width: 0;
  }
  .react-select__clear-indicator{
    cursor: pointer;
  }
  .sub-label {
    color: var(--color-sponsor-tier);
    font-size: var(--font-size-tertiary-heading);
  }
}

.multiSelect-groupStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.selectOption .optionLabel{
  font-size: 1rem;
  color: var(--color-secondary);
}

.selectSubOption .subOptionLabel{
  font-size: 0.9rem;
  margin-right: 0px;
  color: var(--color-secondary);
}

.multiSelect-BadgeStyle {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
}

.invoiceContainer {
  min-height: 86vh;
  position: relative;
}

.invoice-accountName {
  display: flex;
  align-items: center;

  .heading-primary, h1 {
    margin-right: 24px;
    margin-bottom: 0;
  }
}

.invoice-formEdit .edit-btn {
  cursor: pointer;
  padding: 4px 12px;
  transition-duration: 0.1s;
  border-radius: var(--border-radius-primary);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: var(--color-heading);
  }
}

.invoice-formEdit-icon {
  margin-right: 5px;
}

.section.companyInfo .companyInfo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.companyInfo {
  gap: max(48px, 5%);
  .formField {
    margin-bottom: 0;
  
    input, > div{
      width: 100% !important;;
    }
  }
}

.tableWrapper, .table{
  padding: 16px;
  background-color: inherit;
  border-radius: var(--border-radius-primary);
  // text-align: center;
  
  *:not(button, a, thead), *:not(.button-primary) > *:not(.button-primary), 
  .table>:not(caption)>*:not(.button-primary)>*:not(.button-primary){
    background-color: transparent;
  }
  
  thead, thead th{
    background-color: var(--color-primary) !important;
  }
}

.tableWrapper tbody tr:hover{
  background-color: var(--sidebarItemBackground);
}

/* load more button */

.button-loadmore {
  background-color: var(--color-heading);
  border: 2px solid var(--color-heading);
  color: var(--color-secondary);
  background-image: none;
  display: inline-block;
  font-size: 0.875rem;
  line-height: normal;
  margin-right: 8px;
  padding: 0.42rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.1s ease 0s;
  border-radius: var(--border-radius-primary);
}

/* load more button noresult found message css */

.myshow-loadresult {
  text-align: center;
  font-weight: 700;
  padding: 0.5rem;
}

.helpText-landingPage{
  text-align: center;
  display: none;
}

/* Floor function buttons (map & legend)change on home page */

.floorplan-function-buttons {
  padding: 0.7rem;

  /* height: 10%; */
  flex: 1;
}

/* Payments Page */

.paymentsContainer {
  min-height: 86vh;  
}
.totalAmount{
  font-weight: bold;
  margin-top: 12px;
  text-align: left;
}

.invoiceSelect{
  height: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;

  .invoiceSelectCheckbox{
    width: 14px !important;
    height: 14px;

    margin-right: 8px;
    vertical-align: middle;
  }
}

.paymentValueInput {
  margin: 0;
}

// pay later and booth purchase page item details
.orderSummary-orderList,
.orderSummary-paymentInfo {
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  &.payLater {
    display: flex;
    align-items: center;
    flex-direction: row;

    > div {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    span {
      margin-right: 8px;
    }

    input {
      height: 14px;
      width: 14px;
    }
  }
}

.payments.billingForm {
  .billing-form-inputs {
    column-count: 2;
    column-fill: auto;
    height: 100%;
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .accountInfoFields > div {
    margin-top: 0;
  }

  .billing-form-inputs > div {
    margin-top: 0;
    margin-right: 40px;
    flex: 1;
  }
  // accept  terms and conditions on payments page
  .billing-form-inputs{
    .acceptTermsandCondition{
      display: flex;
      padding: 10px 0;

      input#pay-now-terms-input{
        margin-right:12px;
      }

    }

  }

  .acceptTermsandCondition{
    display: flex;
    padding: 10px 0;

    >div{
      flex: 1;
    }
  }
}

.checkboxContainer{
  display: flex;
  align-items: center;
}

.cardDateCvv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  .formField {
    margin: 0;
  }

  .formField:first-child{
    margin-right: 20px;
  }
}

.paymentValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  background-color: transparent;

  .paymentValueInput {
    position: relative;
    width: 130px;
    margin: 0;
    background-color: transparent;

    input {
      margin-left: 15px !important;
      width: 85% !important;
    }
  }
}

/* .paymentValueInput::before {
  content: "$";
  position: absolute;
  left: 2px;
  bottom: 15px;
} */

.payments .formField input {
  width: 100%;
}

.formField {
  select, textarea, > div {
    width: 100%;
  }
}

.formField.multiSelect > div, .formField.multiSelect > div *{
  background-color: inherit;
  color: inherit;
  border-radius: var(--border-radius-primary);
}


/* Footer */

.footerWrapper {
  max-width: 100%;
  width: 100%;

  .footer{
    height: 5rem;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border-top: 1px solid #ccc;
  }
}

.showCycleExternalLink {
  display: flex;
  align-items: center;
}

.poweredBy {
  font-size: 14px;
  letter-spacing: 0.7px;
  font-weight: 350;
  color: var(--color-secondary);
}

.showcycleFooterImage {
  height: 11.458px;
  margin: 0px 0px -1px 5px;
}

.footerLink {
  color: var(--color-nav-link);
  font-size: 12px;
  padding: 6px 12px;
  background-color: transparent;
  font-weight: var(--defaultFontWeight)
}

.footer-button {
  background: var(--color-primary);
  outline: none;
  border: none;
}

.footerLink:hover {
  color: var(--color-nav-link-hover);
}

/* Page Not Found */

.fullScreen {
  /* background-color: #333; */
  width: 100%;
  height: 100vh;

  font-family: Arial var(--color-secondary);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.internalServerError {
  cursor: pointer;
}

.error-container {
  width: 50%;
  display: block;
  margin: 0 auto;
}

.error-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner-content {
  position: relative;
}

.errorNum {
  font-size: 8em;
}

.eyeOuterDiv {
  height: 120px;
  width: 100px;
  background-color: red;
  border-radius: 50%;
}

.eyeMainDiv {
  background-color: var(--color-primary);
  border-radius: 50%;
  display: inline-block;
  height: 75px;
  width: 57px;
  position: absolute;
  top: 22px;
  right: 22px;
}

.eyeMoving {
  background-color: var(--color-secondary);
  border-radius: 50%;
  bottom: 56.1px;
  content: "";
  height: 33px;
  width: 33px;
  right: 33px;
  position: absolute;
}

.error-heading {
  margin: -1rem 0 2rem;
  text-transform: uppercase;
}

.error-description {
  margin-bottom: 4rem;
}

/* .error-span{
  font-style: italic;
} */

.pagenotfound-heading {
  color: var(--color-heading);
  font-size: 3rem;
}

/* unpublished show */

.unpublishedShowContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.unpublished-show {
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 7%;


  .exhibitorLoginHelp{
    .message{
      margin-bottom: 2rem;
    }
    a{
      padding: 6px 12px;
      font-size: 1.8rem;
    }
  }
}

/* Page not found error page css */

.pagenotfound-description {
  font-weight: 500;
  font-size: 25px;
  font-family: sans-serif !important;

  /* margin-bottom: 2rem; */
  margin: 1rem 0 2rem;
}

.pagenotfound-heading, .pagenotfound-description{
  position: relative;
  background-color: transparent;
}

.error-container {
  position: relative;
}

.pagenotfound-image {
  width: 20vw;
  transform: scaleX(-1) rotate(-50deg);
  position: absolute;
  bottom: 12vh;
  right: 1vw;
}

.pagenotfound-btn {
  font-family: sans-serif !important;
}

.back-to-home-text:hover {
  color: var(--color-primary);
}

/********************* No search result form **********************/

.noSearchResultSuggestion {
  ul li {
    list-style-type: disc;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-secondary);
    line-height: 24px;
  }

  margin-top: 2rem;
  padding-left: 10.5rem;

  &.exhibitor {
    width: 90%;
    margin: auto;
    padding: 0;
    margin-top: 20px;
    max-width: fit-content;
  }
}

.noResultErrorMsg {
  font-size: 16px;
  font-weight: 400;

  /* color: var(--bs-red); */

  span {
    font-weight: bold;
  }
}

.filterSearch .nav-link {
  color: var(--color-nav-link);
  font-weight: 400;

  &:hover{
    color: var(--color-nav-link-hover);
  }

  &.active {
    color: var(--color-nav-link-hover);
    background-color: transparent;
    font-weight: 650;
    // border-bottom: 2px solid var(--color-heading);
    border-radius: 0;
  }
}

/************ hashtag input / category ***************/

.categoriesContainer, .exhCustomCategories {
  display: flex;
  flex-wrap: wrap;
}

.customTagsInput {
  margin-bottom: 0;
}

.exhCustomCategories {
  .autoCompleteSearchResults {
    width: 100%;
    margin-bottom: 20px;
    background-color: var(--sidebarItemBackground);
  }

  .category {
    display: flex;
    align-items: center;
    // font-weight: 500;
  }
}

.crossIcon{
  background-color: #d34250bd !important;
  max-height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  &:hover{
    background-color: #d34250 !important;
  }
}

.crossIconRed {
  margin: 0;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d34250bd !important;

  svg{
    height: 100%;
    width: 100%;
    fill: #ffffff;
  }

  &:hover{
    background-color: #d34250 !important;
  }
}

.customTagsInput {
  margin-bottom: 0;
}

.autoCompleteSearchResults {
  margin-top: 0 !important;

  .result {
    cursor: pointer;
    background-color: var(--color-primary);
    color: var(--color-heading);

    &:hover {
      background-color: var(--sidebarItemBackground);
      color: var(--color-heading-hover);
    }
  }
}

// Theme switcher
.themeSelectContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  transition-duration: .1s;
  margin-top: 10px;
  background-color: #fff;

  .themeContainer{
    width: 45px;
    background-color: #fff;
  }

  .theme{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 1rem;
    position: relative;
    cursor: pointer;
    transition-duration: .1s;

    &.light{
      background-color: var(--color-primary);
      border-color: var(--color-primary);
    }
    &.dark{
      background-color: #000;
      border-color: #000;
    }
    &.curiousBlue{
      background-color: #2497cf;
      border-color: #2497cf;
    }
    &.auroraGlow{
      background-color: #154759;
      border-color: #154759;
    }
    &.seascape{
      background-color: #385371;
      border-color: #385371;
    }
    &.delphinium{
      background-color: #004e72;
      border-color: #004e72;
    }

    &.selected{
      width: 26px;
      height: 26px;

      &::after{
        content: '';
        display: block;
        width: 45px;
        height: 45px;
        border: 6px solid;
        border-color: inherit;
        background-color: transparent;
        border-radius: 50%;
        position: absolute;
        align-self: center;
        left: -35.5%;
        top: -35.5%;
        animation: .1s cubic-bezier(0, 0.2, 0.8, 1) 0s 1 grow;
      }
    }
  }
}

@keyframes grow {
  0%{
    transform: scale(0);
  }

  50%{
    transform: scale(.5);
  }

  100%{
    transform: scale(1);
  }
}

.uploaded-file{
  margin: 10px 0;
}
.uploadedFile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #80808059;
  padding: 5px 10px;
  border-radius: var(--border-radius-primary);

  button{
    padding: 5px;
    border: none;
  }
}

.downloadPDF {
  border: 1px solid rgba(128, 128, 128, 0.3490196078);
  padding: 5px 10px;
  border-radius: var(--border-radius-primary);
  background-color: inherit;
  color: var(--color-secondary);

  svg{
    fill: var(--color-secondary);
  }

  &:hover{
    background-color: inherit;
    color: inherit;
    border-color: inherit;
  }
}

.playerWrapper{
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.reactPlayer{
  position: absolute;
  top: 0;
  left: 0;
}

.floorplanMapSelect .form-select, .landingPage-search input{
  border: 0;
}

.formField .react-tel-input .flag-dropdown, .formField .react-tel-input .flag-dropdown.open{
  background-color: var(--sidebarItemBackground);

  *{
    background-color: inherit !important;
  }
}

// scroll to top btn.

#scrollToTopBtn{
  z-index: 99;
  
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  cursor: pointer;

  right: 20px;
  bottom: 20px;
  height: 50px;
  width: 50px;

  background-color: var(--background-color-disabled);

  svg{
    transition-duration: .2s;
    stroke: var(--color-secondary);
    stroke-width: 1.5px;
  }

  &:hover{
    box-shadow: 3px 3px 9px -5px var(--color-secondary);
    svg{
      transform: translateY(-3px);
    }
  }
}

#scrollProgressVal{
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: var(--background-color-disabled);
  border-radius: 50%;

}

// on Profile page.
.theme-auroraGlow {
  .profile-info.sidebarItemWrap {
    background-color: #0e526b !important;
  }
}

// terms and conditions text underline
.pay-now-terms a{
  text-decoration: underline !important;
}

// Booth personnel table
.addBoothPersonnel-row{
  cursor: pointer;
  padding: 0 !important;
  
  div.placeholderText{
    font-weight: 700;
    padding: 12px;
    text-align: center;
  }

  svg{
    width: 20px;
    height: 20px;
  }
}

.contactRoleForm{
  .formInputs{
    margin-bottom: 24px;
  }

  .formBtnContainer{
    gap: 12px;
  }

  .formField {
    // margin: 0;
    flex: 1;
  }

  .react-select__menu{
    background-color: var(--sidebarItemBackground);
  }
  .react-select__option:hover, .formField .react-select__option:active{
    background-color: var(--color-primary);
  }

  .subLabel{
    margin-left: 24px;

    &::before{
      content: "(";
    }

    &::after{
      content: ")";
    }
  }
}

.registrationContainer .contactsWrapper .table-responsive{
  max-height: 400px;
  overflow-y: auto;
}

.ContactSearchFormContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .searchForm{
    width: 350px;
    max-width: 100%;
    
    .emailFieldWrapper {
      position: relative;
      display: flex;
      align-items: center;
    }
    .emailFieldWrapper .formField {
      width: 100%;
      margin-top: 0;
    }
    .emailClearIcon {
      color: #000;
      position: relative;
      top: 7px;
      right: 2px;
      transform: translateY(-50%);
      margin-left: -30px;
      font-size: 25px;
      cursor: pointer;
    }
  }

  .formBtnContainer *{
    flex: 1;
  }

  label{
    display: none;
  }

  .contactForm{
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: center;
    gap: 12px;

    .btn{
      height: 40px;
    }
  }
}

.attendeeRegistrationForm{
  .modalHeader{
    margin-bottom: 0;
  }
  .modalBody{
    margin-top: 0;
  }

  .billing-form-inputs{
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
.singleRegistrationForm .billing-form-inputs{
  grid-template-columns: repeat(2, minmax(200px, 1fr));
}

// registrationForm duplicateResModal
.modalContainer.duplicateResModal { 
  height: auto;
}

.summaryTable{
  margin: 12px 0 24px;
  margin-left: auto;
  margin-right: 24px;
  td{
    padding: 2px 16px 2px 0;
    
    &.orderList-item-name{
      text-align: right;
      &::after{
        content: ":";
      }
    }

    &.orderList-item-price{
      text-align: right;
      font-weight: 400;
    }
  }
}

.statusContainer{
  font-size: 14px;

  .statusMessage{
    display: flex;
    gap: 4px;
    align-items: center;
  }

  .statusText{
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  svg{
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }

  .approved{
    color: green;
    svg{
      fill: green;
    }
  }
  .pending{
    color: orange;
    svg{
      fill: orange;
    }
  }
  .notApproved{
    color: red;
    svg{
      fill: red;
    }
  }
}

.singleRegistrationWrappre .contactDetails{
  position: relative;
}

.attendeeProductsTable{
  max-height: 300px;
  overflow-y: auto;
  // margin-bottom: 16px;

  .table > thead{
    top: -16px;
  }

  .table-active{
    background-color: var(--sidebarItemBackground);

    td{
      background-color: transparent;
    }
  }

  .attendeeRow:hover{
    background-color: var(--color-div-hover);
  }
}

.profile-info {
  margin-bottom: 24px;
}

// contacts table
.contactDetails{
  display: flex;
  // align-items: center;
  gap: 8px;

  .contactImageContainer{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    .contactInitials{
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: var(--font-size-secondary-heading);
      font-weight: var(--font-weight-secondary-heading);
      color: #979797;

      height: 100%;
      width: 100%;
      border-radius: 50%;
      background-color: var(--sidebarItemBackground) !important;
    }

    .contactImage{
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: top;
    }
  }

  .contactInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .contactName{
      font-size: var(--font-size-paragraph);
    }

    .contactEmail{
      font-size: calc(var(--font-size-paragraph) - 4px);
    }
  }

  .registrationStatus {
    display: flex; 
    align-items: flex-end;
    margin-bottom: 2px; 
  }
}

.contactStatusWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.registrationIcons{
  display: flex;
  align-items: center;
}
.registrationIcon{
  padding: 6px;
  width: fit-content;
  transition-duration: 0.3s;
  opacity: 0.7;

  .showOnHover{
    display: none;
  }

  &:hover {
    opacity: 1;
    .hideOnHover{
      display: none;
    }
    .showOnHover{
      display: block;
    }
  }
  
  &:hover svg{
    fill: var(--background-color-primary-button-hover);
  }

  &.registrationIcon--active, &.registrationIcon--transfer{
    cursor: pointer;
  }

  &.registrationIcon--cancel{
    cursor: pointer;
    &:hover svg{
      fill: red;
    }
  }
}

// booth personnel / contact roles table
.modalContainer.registrationViewModal .modalContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactRoles{
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  // justify-content: center;
  text-align: center;

  .contactName, .accountName {
    margin-bottom: 0;
  }

  .RegistrationAccountLogo{
    height: 150px;
    width: auto;

    img{
      height: 100%;
      width: auto;
      object-fit: cover;
    }
  }

  .accountName{
    font-size: 16px;
  }
  .contactName{
    font-size: 38px;
  }

  .contactTitle {
    font-size: 14px;
    font-weight: 400;
    opacity: 0.6;
    margin-bottom: 12px;
  }

  .contactRole{
    display: flex;
    align-items: center;
    // justify-content: center;
    // padding: 0 6px;
    min-width: 200px;
    // border-radius: var(--border-radius-primary);
    // border: var(--border);
    // background-color: var(--color-primary);

    .crossIconRed{
      background-color: #d34250bd;
      margin-left: 6px;

      &:hover{
        background-color: #d34250;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      opacity: 0.6;
    }
  }

  .boothPersonnel{
    cursor: pointer;
  }

}

.boothPersonnelInfoList {
  display: flex;
  flex-direction: column;
  .exhibitor-booth-number{
    border-right: 0;
  }
}

.boothSelect-modal{
  .boothPersonnelInfoList{
    align-items: center;
    gap: 12px;
    margin-top: 12px;
  }

  .boothItem{
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 4px 8px;
    // background-color: var(--sidebarItemBackground);
    width: fit-content;

    &:hover{
      cursor: pointer;
      font-weight: 400;

      .crossIconRed{
        background-color: #d34250;
      }
    }
  }
}

.roleDeleteConfirmation-modal{
  .confirmationBtns{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 12px;
  }
}

// Contacts table styles
.card-data-label-container{
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-data-label{
  display: none;
}

.contact-row{
  position: relative;
}
input.contact-checkbox{
  width: 16px;
  height: 16px;
}

.contact-row .edit-btn{
  button{
    padding: 5px 10px 6px;
  }
}

.attendeeQnsForm{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(300px, calc(50% - 12px)), 1fr));
  gap: 0 24px;
  margin-top: 12px;

  .formField > label {
    font-size: var(--font-size-paragraph);
  }
}
/* sponsors list page */
.sponsorsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // align-items: center;
  gap: 12px;
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  // padding: 16px;
}

.sponsorCard {
  padding: 12px;
  box-sizing: border-box;
  min-width: 160px;
  max-width: 100%;
  // border: var(--border);
  // border-radius: var(--border-radius-primary);
  // background-color: #fff;
  // display: flex;
  // align-items: center;
  // justify-content: center;

  // text-align: center;  
  position: relative;
}

.sponsorImage {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  // margin: auto;  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// sponsor list page
.sponsor-list-wrapper {
  .sponsor-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sponsor-list-body { 
    .section-sponsors__header {
      border-bottom: var(--border);
    }
  }
}

//Sponsorship Product page
.sponsorshipProduct {
  padding: 12px 20px;
}

.backIcon{
  width: 30px;
  height: 30px;
  cursor: pointer;

  path{
    fill: var(--background-color-primary-button) !important;
  }
}

.theme-dark{
  .backIcon{
    path{
      fill: rgb(182, 182, 182) !important;
    }
  }
}

.sponsorshipProductHeader{
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h1.heading-primary{
    margin: 0px;
  }
  .view-toggle-buttons{
    margin: 0px;
    padding: 0px;
  }
}

.sponsorshipProductForm{
  margin:0px auto;

  .billing-form-inputs{
    display: flex;
    justify-content: space-between;

    .formFieldGroup{
      width: 45%;
    }
  }
}

//SponsorshipProductTableView.
.attendeeProductsTable.tableWrapper{
  margin-bottom: 16px;
  max-height: 500px ;
  position: relative;
  padding: 16px 16px 0px 16px;

  tbody{
    .cellCheckbox{
      width: 8%;
      vertical-align: middle;  /* Vertically center the checkbox */
      input{
        display: block;
        margin-left: auto;        /* Center the checkbox horizontally */
        margin-right: auto;       /* Center the checkbox horizontally */
      }
    }
    .cellImage{
      text-align: center;
      width: 20%;
    }
    .cellCurrency{
      width: 15%;
    }
    img{
      width: 70px;
      object-fit: cover;
      border-radius: var(--border-radius-primary);
    }
    .expandableDescriptionComponent{
      .sponsorship.expandableDescription {
        -webkit-line-clamp: 1; // set the line of description to be visible in sponsorship table view.
      }
    }
  }
}

// sponsorship description toggle css
.expandableDescriptionComponent{
  position: relative;

  .sponsorship.expandableDescription {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }

  .sponsorship.expandableDescription.expanded {
    display: inline-block;
  }

  a{
    font-size: var(--font-size-label);
  }
}

tfoot {
  position: sticky;
  bottom: -1px; //to fix at the bottom of the table 
  z-index: 1;
  background-color: var(--color-primary) !important;
  border-bottom: none;

  td{
    border-bottom: none;
  }
  td.orderList-item-price.no-border{
    font-weight: 400 !important;
  }

  td.orderList-item-name.no-border{
    text-align: right;
  }

  .attendeeRow:hover{
    background-color: var(--color-primary) !important;
  }
}
.amount-summary {
  display: flex;
  justify-content: flex-end;
  // align-items: flex-start;
  gap: 1rem;
  margin-right: 150px;

  .summary-labels, 
  .summary-values {
    text-align: right;

    p {
      margin: 0;
      padding: 5px;
    }
  }

  .summary-values p {
    font-weight: 400 !important;
  }
  .summary-values .heading-secondary {
    opacity: 0;
    visibility: hidden;
  }
}

.theme-dark, .theme-auroraGlow{
  .itemDescription{
    svg{
      path{
        fill: white;
      }
    }
  }
}

.itemName{
  font-weight: var(--font-weight-secondary-heading);
}

.contact-checkbox{
  cursor: pointer;
}
//SponsorshipProductGridView.
.sponsorProductCardWrapper{
  max-height: 470px;
  overflow: auto;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  padding: 16px;
  margin-bottom: 16px;

  .sponsorhsipProductCardView{
    min-height: 210px;
    padding: 16px;
    border: var(--card-border);
    border-radius: var(--card-border-radius);
    display: flex; 
    justify-content: space-between;

    .sponsorshipProductDetails{
      width: 50%;
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr;
      align-content: space-between; /* Distribute space between lines */
    }
    .sponsorshipProductImage{
      width: 50%;
      padding: 5px;
      max-height: 200px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: var(--border-radius-primary);
      }
    }
    .expandableDescriptionComponent{
      .sponsorship.expandableDescription {
        -webkit-line-clamp: 2; // set the line of description to be visible in sponsorship card view.
      }
    }
  }
  .sponsorhsipProductCardView:hover{
    background-color: var(--color-div-hover);
  }

  .cardActive{
    background-color: var(--color-div-hover);
  }
}

// attendee list page
.attendee-list-wrapper {
  .attendee-list-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    flex-wrap: wrap;

    .attendee-button-wrapper {
      display: flex;
      
      .button-primary {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 12px;
      }
      
      .button-primary:first-child {
        margin-right: 12px;
      }
    }
  }
  .attendee-letter-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
    margin: 10px auto;

    .letter-link {
      font-weight: 500;
      text-decoration: none;
      padding: 0.25rem 0.5rem;
      transition: background-color 0.3s ease;
      // border-right: var(--btn-primary-border);
    }
    .letter-link:last-child {
      border: none;
    }
    .letter-link:hover {
      text-decoration: underline;
    }
    .letter-link.active {
      font-weight: 700;
      text-decoration: underline;
    }
    .letter-link.disabled {
      color: var(--color-disabled);
      pointer-events: none;
      cursor: not-allowed;
    }
  }
  .attendee-list-body {
    display: flex; 
    gap: 24px 20px;

    .attendee-column {
      flex: 1;
    }

    .attendee-letter-group { 
      margin-bottom: 20px; 
    }

    h2 {
      margin-top: 15px;
      font-family: "Noto Serif", serif;
    }

    .attendee {
      font-family: "Noto Serif", serif;
      
      .attendee-data {
        .attendee-full-name, .attendee-title, .attendee-last-name, .attendee-first-name { 
          color: var(--color-secondary) !important;
          font-style: italic;
          font-weight: 400;
        }
        .attendee-account-name {
          color: var(--color-secondary) !important;
          font-style: normal; 
        } 
      } 
    }
  } 
}

// Media Queries.

@media (max-width: 768px) {
  .attendee-list-body {
    flex-direction: column;
    gap: 0 !important;
  } 
}
 

@media only screen and (max-width: 1240px) {
  .floorplan-function-buttons {
    padding-top: 1rem;
  }
  .sponsorProductCardWrapper{
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .profileDropdown.show{
    padding-left: 0;
  }
  .navbar-main{
    .nav-item{
      width: 100%;
    }
    .navbar-nav{
      align-items: center;
      width: 100%;
      text-align: center;

      .nav-link:active, .nav-link:hover, .nav-link.active{
        background-color: var(--sidebarItemBackground);
      }
    }
    .dropdown-toggle{
      justify-content: center;
    }

    div#basic-navbar-nav{
      margin-left: 0;
      margin-right: 0;
      background-color: var(--color-primary);
    }
  }

  :root, .theme-dark, .theme-auroraGlow {
    --font-size-primary-heading: 28px;
    --font-size-secondary-heading: 18px;
  }

  .pagenotfound-image {
    width: 30vw;
    bottom: 30vh;
  }
}

/* charge table for smaller devices*/
@media screen and (max-width: 768px) {
  .heading-primary{
    margin-bottom: 8px;
  }
  .heading-secondary{
    margin-bottom: 4px;
  }
  .container{
    width: 100%;
  }

  .section.chargeInfo .heading-primary span {
    font-size: 18px;
  }

  .table-buttons {
    align-self: end;
  }

  .card-data-label{
    display: inline;
  }

  .contactDetails{
    flex-direction: column;
    gap: 0;

    .contactImageContainer{
      width: 60px;
      height: 60px;
    }

    .contactInfo{
      align-items: center;
    }
  }

  .contact-row{
    gap: 12px;
  }

  .contactsWrapper, .boothPersonnelWrapper{
    .contacts-table, .boothPersonnel-table{
      .contact-row {
        td{
          margin: 0;
        }

        .edit-btn{
          margin: 0;
          button{
            padding: 4px 38px;
          }
        }
      }
    }
  }

  .section.chargeInfo .heading-primary {
    /* font-size: 16px !important; */
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 18px;
    align-items: baseline;
  }

  .table-responsive {
    overflow: auto;
    margin: 20px 0;
    max-height: 500px;
  }

  .boothPersonnelWrapper, .contactsWrapper {
    background-color: var(--color-primary);
    padding: 0;

    // do not show scroll bar
    .table-responsive{
      height: auto;
      max-height: fit-content;
    }
  
    .boothPersonnel-table, .contacts-table {
      th {
        display: none;
      }
  
      tbody {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
        gap: 16px;
  
        td {
          border: none;
          padding: 0;
        }
  
        tr {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: var(--border-radius-primary);
          // background-color: var(--sidebarItemBackground) !important;
          border: var(--border);
          padding: 24px 12px;

          .contactId{
            display: none;
          }
          
          .contactRole {
            box-shadow: 1px 1px 7px -6px var(--color-secondary);
            background-color: inherit;

            &:hover{
              background-color: var(--color-secondary);
              span{
                color: var(--color-primary);
              }
            }
          }
        }
      }
    }
  }
  .invoice-summary{
    gap: 0;
  }
  .invoice-summary .invoice-summary-item::after{
    display: none;
  }

  .modalWrapper .modalContainer {
      padding: 20px; 

      .modalHeader .modalTitle {
        margin-bottom: 0;
      }

      .modalHeader, .modalBody, .modalFooter {
        margin: 1rem;
      } 
  }
  
  .exhibitorHomeWrapper img {
    width: 100%;
  }
  .sponsorProductCardWrapper{
    .sponsorhsipProductCardView{
      min-height: 250px;
    }
  }
}

// media queries
@media screen and (max-width: 576px) {

  :root, .theme-dark, .theme-auroraGlow {
    --font-size-primary-heading: 24px;
    --font-size-secondary-heading: 17px;
    --font-size-paragraph: 14px; /* Paragraph font size */
    --line-height-paragraph: 1.4;
  }

  .body-content-wrapper{
    min-height: 65vh;
  }
  
  .card-list {
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1rem 12px;
  }

  .card {
    width: 100%;
  }

  .myshow-listcard{
    margin: 1rem 12px;
  }

  .shows-list-wrapper nav{
    flex-direction: column;

    div:first-child{
      margin-bottom: 12px;
    }
  }
  #nav-tab-exhibitorHome, #myshowTabs{
    width: 100%;
    
    .tabList{
      width: 100%;
    }
  }

  .orgFilterWrap{
    width: 100%;
    div, select{
      width: 100%;
    }
  }

  .tabList button{
    width: 50%;
  }

  .helpText-landingPage{
    display: block;
  }
  .exhibitorForm-formFields {
    flex-direction: column;
  }

  .myshow-noresult {
    padding: 1rem;
  }

  /*loadmore buton for my show planner page */

  .button-loadmore, .no-sessions {
    margin-left: 1rem;
  }

  .payments.billingForm .billing-form-inputs {
    flex-direction: column;

    > div {
      width: 100%;
    }
  }

  .section.companyInfo .companyInfo {
    grid-template-columns: repeat(1, 1fr);
    gap: 0 20px;
  }

  .companyInformation-container {
    display: flex;
    flex-direction: column;
  }

  .companyInfo .formField input {
    width: 100% !important;
  }

  .formField {
    select, textarea, > div {
      width: 100% !important;
    }
  }
  .sponsorProductCardWrapper{
    grid-template-columns:1fr;
    .sponsorhsipProductCardView{
      min-height: 200px;
    }
  }
}


@media screen and (max-width: 550px) {
  .pagenotfound-image {
    width: 30vw;
    top: -15vw;
  }

  .login.User, .login.Exhibitor{
    // .login-wrapper{
    //   width: 400px;
    //   box-shadow: var(--box-shadow-login);
    // }

    .social-login{
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 12px;
    }
  }

  .login.Exhibitor {

    // .social-login-wrapper,
    // .social-login-subWrapper{
    //   flex-direction: column;
    //   margin-top: 24px;
    // }

    .exhibitorLogin-helpText {
      margin-top: 12px;
    }

    .social-login{
      &:first-child{
        margin-right: 0;

        &::after{
          display: none;
        }
      }
    }
  }
}


@media screen and (max-width: 480px) {

  .modal .modalWrapper .modalContainer{
    width: 100% !important; 
  }
  .wrapperContainer{
    padding: 0;
  }

  .card {
    width: 100%;
  }

  .footerWrapper{
    padding-bottom: 24px;
    margin-top: 24px;

    .footer{
      height: auto;
      gap: 24px;
    }
  }

  .footerLink-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;

    .footerLink{
      padding: 0;
    }
  }
  .sponsorshipProductForm{

    .billing-form-inputs{
      display: block;
  
      .formFieldGroup{
        width: 100%;
      }
    }
  }
}


/* navbar height  for the toggle button for smaller devices*/
@media screen and (max-width: 436px) {
  .body-content-wrapper {
    margin-top: 3rem;
  }
}


@media only screen and (max-width: 432px) {
  .floorplan-function-buttons {
    padding-top: 0;
  }
  .sponsorProductCardWrapper{
    .sponsorhsipProductCardView{
      min-height: 250px;
    }
  }
}